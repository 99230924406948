import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "- 노르웨이 키즈 스마트워치 1위 브랜드 엑스플로라 XGO3 국내 최초로 단독 출시",
  "- 스마트폰 형태로 공급되는 국내 키즈폰 시장에 최신형 워치 타입 단말 선보여",
  "- 사전예약자 1천명에 한해 핀다이렉트 키즈워치 전용요금 3개월간 무료 제공",
]

const content = () => (
  <div>
    <br />

    <p>
      스테이지파이브가 북유럽 감성의 키즈워치 XGO3<sup>Gen2</sup>를 출시한다. 해당 워치 시리즈는 노르웨이 기반 키즈 스마트워치
      전문 회사인 엑스플로라(Xplora)의 제품으로 유럽 전역과 북미 등 글로벌 시장에서 150만대 이상 누적판매 기록이 있는 품질이
      보장된 인기 제품이다. 국내 키즈폰은 통신사들이 스마트폰 형태의 단말과 고가 요금제 위주로 제공하고 있는 가운데, 4~10세
      아이들에게 필요한 핵심 기능들을 합리적인 가격에 이용할 수 있어 자녀의 스마트폰 사용을 최대한 늦추고자 하는 부모들에게 큰
      관심을 얻을 것으로 예상된다.
      <br />
      <br />
      XGO3 키즈워치는 통화•SMS•위치조회 등의 기본 기능과 더불어 아이의 활동에 기반한 걸음수 산정으로 적립한 포인트로 전용 앱을
      통해 음악, e-book, 게임 등 다양한 콘텐츠를 구매할 수 있어 아이들에게도 활용도가 높을 것으로 전망된다. 색상은 핑크 제브라,
      블루 엘리펀트 2종으로, IP68 방수, 5MP 카메라, GPS가 탑재되어 있으며 학교수업모드, 위급상황 SOS, 자녀 위치조회 등의 다양한
      기능을 제공하고 있다.
      <br />
      <br />
      키즈워치를 직접 제조 및 유통한 경험이 있고, 다년간 다양한 인기 키즈폰을 통신사를 통해 지속 선보였던 스테이지파이브는 XGO3
      국내 최초 독점 출시를 기념하여 1월 20일부터 10일간 핀다이렉트몰에서 1천대 한정으로 사전예약 이벤트를 진행, 사전예약자에 한해
      키즈워치 전용 요금제를 3개월간 무료로 제공한다. 단말 가격은 179,000원(VAT 포함)이며, 월 5,500원에
      통신서비스(데이터2GB/음성200분/문자200건)를 이용할 수 있다. 공식 출시는 1월 31일부터이며 모든 구매 고객에게 1년간 무상 AS를
      제공한다.
      <br />
      <br />
      서상원 스테이지파이브 대표이사(CEO)는 “키즈워치는 파손 및 분실 위험도가 상대적으로 낮아 10세 이하 자녀들에게 활용도가 높은
      단말인데 고객 입장에서는 선택의 폭이 좁은 상황”이라며, “유럽과 북미 시장에서 인기있는 키즈워치를 국내 최초로 선보이게 되어
      기쁘며, 앞으로도 스테이지파이브는 기존 통신사 서비스의 단순 재판매에 국한되지 않고, 고객이 필요로 하는 다양한 형태의
      디바이스와 서비스를 지속 출시할 계획”이라고 밝혔다.
      <br />
      <br />
    </p>
    <p>
      ※ 사전예약 이벤트 링크:{" "}
      <a href="https://www.pindirectshop.com" target="_blank">
        핀다이렉트 _ 홈
      </a>
    </p>
    <div>
      ※ 제품 사진 자료
      <br />
      <div style={{ textAlign: "center" }}>
        <img src="/images/xplora1.png" style={{ width: "200px", display: "inline-block" }} />
        <img src="/images/xplora2.png" style={{ width: "200px", display: "inline-block" }} />
      </div>
    </div>
    <br />
    <p>
      ※ XPlora 홈페이지:{" "}
      <a href="https://shop.myxplora.com/?srsltid=AfmBOooBgHk3u-dYnHNKELmJB_oGuLXxNgLBbYQYOYtVq1Su4Bi8rKEF" target="_blank">
        Top-Rated Smart Watches for Kids | Xplora USA
      </a>
    </p>
  </div>
)

const press55 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="리더 이길욱 mattew.lee@stagefive.com"
      date="2025년 1월 20일(월) 10:30 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 북유럽 감성 키즈워치 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press55
