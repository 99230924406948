import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "- Circles의 유럽, 남미, 아시아 등 다양한 글로벌 레퍼런스 기반 표준기술 협력",
  "- 비대면 개통, AI CS 및 빌링시스템 등 자체 구축한 플랫폼 기반 현지화 기술 녹여 국내 최초 Full MVNO 사업 본격 추진",
]

const content = () => (
  <div>
    <br />

    <p>
      제4이동통신 사업자를 준비했던 스테이지파이브(대표이사 서상원)가 글로벌 CPaaS(Communications Platform-as-a-Service) 회사인
      Circles MVNE International B.V.(이하 Circles MVNE)와 Full MVNO 및 Managed MVNE 서비스 협력을 위한 전략적 파트너십 계약을
      체결했다고 14일 밝혔다.
      <br />
      <br />
      Circles MVNE는 유럽, 남미, 중동 및 아프리카, 아시아 태평양 지역에서 커뮤니케이션 플랫폼 솔루션을 제공하는 글로벌 사업자로,
      안전하고 확장성이 뛰어난 유비쿼터스 솔루션을 통해 데이터, 음성, 비디오, SMS/MMS 및 미디어 콘텐츠 제공 서비스를 지원하고
      있다. 특히, Full MVNO 솔루션은 확장 가능한 멀티테넌시<sup>2</sup> 서비스 제공 플랫폼으로 MVNO가 클라우드 혹은 온프레미스
      <sup>3</sup> 를 통해 새로운 서비스를 맞춤형으로 출시할 수 있도록 지원한다.
      <br />
      <br />
      스테이지파이브는 작년 초 제4이동통신사업 추진을 위해 설립한 스테이지엑스가 주파수 할당대상법인으로 선정됨에 따라 국내 MVNO
      사업자로서는 최초로 코어망 구축을 준비하던 중 정부의 취소로 사업중단이 불가피하게 되었으나, 신속한 조직정비 및 사업구조
      개선을 통해 작년 3분기 창사 이래 첫 분기실적 흑자전환을 발표하면서 2025년 Full MVNO 사업추진을 통해 신성장 동력을
      확보하겠다고 밝힌 바 있다. 실제 MVNO 사업은 지난 12월 기준 신규 및 번호이동시장 가입자 1위
      <sup>MNO 자회사 제외, 후불기준</sup>를 기록하고, 글로벌 로밍서비스는 수익성이 큰 폭으로 개선되는 등 4분기에도 지속적인
      성장세와 흑자기조를 유지하는 가운데, 이를 기반으로 국내에서는 Full MVNO 사업이 아직 전례가 없는 만큼 해외 시장에서 20여 년
      간 다양한 레퍼런스를 쌓은 검증된 글로벌 사업자와의 기술협력을 통해 완성도 높은 서비스를 준비하겠다는 계획이다.
      <br />
      <br />
      서상원 스테이지파이브 대표이사(CEO)는 “Circles MVNE는 전세계 다양한 국가의 MVNE<sup>4</sup> 나 MVNA<sup>5</sup> 사업자를
      대상으로 코어망 연동과 솔루션 구축서비스를 제공해온 경험이 있다. 국내 통신시장에도 경쟁력 있는 MVNE와 Full MVNO 사업자가
      등장해야 할 시기가 도래했다고 보고 있으며, 스테이지파이브는 글로벌 경쟁력을 갖춘 Circles와 전략적 파트너십 계약을 기반으로
      클라우드 기반 코어망과 멀티테넌시 플랫폼을 갖추고 Full MVNO 사업을 성공적으로 준비해 나가겠다.”고 밝혔다.
      <br />
      <br />
      <hr></hr>
      <p>
        <sup>1</sup> Circles MVNE: 글로벌 디지털 통신사인 Circles(본사 싱가포르)의 100% 자회사. Circles는 2016년 혁신적인 디지털
        통신 플랫폼을 기반으로 MVNO 사업을 시작, 사용자 경험의 획기적 개선과 투명하고 유연한 서비스 제공을 통해 전통적인
        통신산업을 디지털화하고 고객 중심의 CPaaS 솔루션을 글로벌 시장으로 확장하여 빠르게 성장, 현재 전세계 15개국에서 서비스를
        제공하고 있음. 디지털 기반 통신사로서 혁신적인 비즈니스 모델과 빠른 성장성, 전통적인 통신사 대비 낮은 운영비용 등의 사업
        경쟁력을 인정받아 Warburg Pincus2020년 투자유치, Sequoia Capital, EDBI(싱가포르 정부 투자기관), Founders Fund(실리콘밸리
        VC) 등으로부터 투자를 유치하면서 글로벌 유니콘 기업(기업가치 10억달러 이상)으로 등극
      </p>
      <p>
        <sup>2</sup> 멀티테넌시(Multitenancy): 소프트웨어 아키텍처의 하나로 여러 고객이 하나의 소프트웨어 환경환경을 공유하면서도
        각자의 데이터와 사용자 환경을 독립적으로 유지할 수 있도록 지원하는 기술
      </p>
      <p>
        <sup>3</sup> 온프레미스(On-premise): 소프트웨어 등 솔루션을 클라우드 같이 원격환경이 아닌 자체적으로 보유한 전산실 서버에
        직접 설치해 운영하는 방식
      </p>
      <p>
        <sup>4</sup> MVNE(Mobile Virtual Network Enabler): MVNO가 이동통신 서비스를 제공할 수 있도록 기술적 인프라와 운영지원
        서비스(BSS/OSS)를 제공하는 전문 기업
      </p>
      <p>
        <sup>5</sup> MVNA(Mobile Virtual Network Aggregator): MVNO와 MNO 사이에서 중개자 역할을 수행하는 비즈니스 모델. MNO로부터
        네트워크 용량을 대향으로 구매한 뒤 이를 여러 MVNO에게 재판매 하거나 서비스를 제공하며 MVNO가 보다 쉽게 사업을 시작하고
        운영할 수 있도록 지원
      </p>
    </p>
  </div>
)

const press55 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="리더 이길욱 mattew.lee@stagefive.com"
      date="2025년 1월 14일 14:00 배포 가능"
      pageInfo=""
      title="스테이지파이브, Circles MVNE<sup>1</sup> 와 전략적 파트너십 사업협정 체결"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press55
