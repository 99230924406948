import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "- 신속한 경영 효율화와 핵심사업 본원의 경쟁력 강화로 3분기 영업이익 턴어라운드",
  "- 핀다이렉트 앱 기반 MVNO, 해외 로밍 등 주요 서비스 가입자수 견고한 성장세",
  "- 제4이통 준비로 발생한 일회성 비용부담 빠르게 해소하며 영업이익 흑자 달성",
]

const content = () => (
  <div>
    <br />

    <p>
      제4이동통신 사업자를 준비했던 스테이지파이브(대표이사 서상원)가 신속한 경영전략 변화를 통해 분기 첫 영업이익 흑자 전환에
      성공했다. 3분기 누적매출은 243억원으로, 주요 사업인 MVNO와 글로벌 로밍 서비스의 가입자 증가 및 비용효율화를 통한 공통비
      감소에 힘입어 3분기 영업이익을 내며 턴어라운드했다. 지난 7월 말 정부의 주파수 할당 최종 취소처분 이후 경영 정상화를 위한
      빠른 태세 전환 전략이 주효했다는 평가다.
      <br />
      <br />
      MVNO 사업은 독자적 빌링 시스템과 AI 기반 고객센터를 갖추고 통신3사 망 연동 등 기술 중심의 통신 밸류 체인을 강화한 결과
      가입자 MNP 시장점유율 KT망 기준 2위<sup>1</sup> 로 올라섰다. 이를 기반으로 10월 말 가입자 10만명 돌파를 앞두고 있다.
      스테이지파이브의 MVNO는 가입부터 개통, 조회 등 모든 과정을 100% 온라인으로 제공한다. 작년 2월 출시한 올인원 통신앱
      핀다이렉트는 ▲요금제 가입 및 개통 ▲사용현황 조회 ▲내 폰 진단 및 시세조회 ▲해외 데이터 로밍 가입 및 현황조회 등 고객 니즈에
      부합하는 편리한 서비스를 제공하고 있어, 고객들로부터 호평을 받으며 꾸준한 가입자 성장으로 9월말 41만명을 돌파했다.
      <br />
      <br />
      글로벌 데이터 로밍 사업은 업계 최초 ‘데이터 완전 무제한’ 상품과 ‘로밍패스’와 같은 혁신적인 상품을 도입하여 데이터 로밍
      시장에서 새로운 트렌드를 이끌고 있다. ‘로밍패스’는 9,900원에 1년간 횟수제한 없이 로밍 상품을 반값에 이용할 수 있는
      구독서비스로 가입 고객 중 52%가 재구매로 이어지며 높은 고객 만족도를 보이는 상품이다. 이러한 로밍 서비스는 자체 핀다이렉트
      앱 서비스와 여행 대표 플랫폼인 야놀자, 인터파크트리플과의 판매 채널 협력을 통해 년내 누적 가입자수 100만 돌파를 앞두고 있다.
      야놀자, 인터파크트리플과의 플랫폼을 연동을 통해 고객의 여행 스케줄에 최적화된 차별적인 해외 로밍 이용 경험을 제공할 예정으로
      양사간 시너지를 통한 강력한 매출 성장이 기대된다
      <br />
      <br />
      서상원 스테이지파이브 대표이사(CEO)는 “제4이동통신사업은 취소되었지만 우리는 이를 준비하는 과정에서 클라우드 기반 코어망 등
      최신 이동통신 기술을 확보하고 고객 편의성을 높인 혁신적인 서비스와 요금제를 치열하게 고민해왔다. 이를 기반으로 Full MVNO
      사업 추진에 역량을 집중할 계획이다. 동시에 글로벌 제조사인 폭스콘과 공동 개발한 중저가형 폴더블폰 등 단말 포트폴리오의
      다각화를 통해 고객 선택권을 확대하여 지속적인 성장세를 보여주겠다.”고 밝혔다.
      <br />
      <br />
      <hr></hr>
      <p>
        <sup>1</sup> ’24.3Q 기준, 통신3사 MVNO 자회사 제외
      </p>
    </p>
  </div>
)

const press54 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="리더 이길욱 mattew.lee@stagefive.com"
      date="2024년 10월 26일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 첫 분기 흑자전환"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press54
